import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { IoIosStar } from "react-icons/io"

const CourseDetails = ({ coursedetails }) => {
  const color = ["#00a8ff", "#8cc749", "#ffbc00", "#eb665f", "#00cbff"]
  return (
    <div>
      <div className="CourseDetails">
        <div className="container">
          <div
            dangerouslySetInnerHTML={{ __html: coursedetails.details.details }}
          ></div>
          <div className="CourseCurriculum DetailsRow">
            <h4 className="course_title">Course Curriculum</h4>
            <div className="page-header"></div>
            <ul className="time_line">
              {coursedetails.weekstatus.map((weekStatus, index) => (
                <li
                  key={weekStatus.week}
                  className={index % 2 === 1 ? "time_line-inverted" : ""}
                >
                  <div
                    className="time_line-badge"
                    style={{ backgroundColor: color[index] }}
                  >
                    <span className="Week">Week</span>
                    <h3>{weekStatus.week}</h3>
                  </div>
                  <div className="time_line-panel">
                    <div className="time_line-body">
                      <p>{weekStatus.details}</p>
                      <Link to={weekStatus.link}>+ View More</Link>
                    </div>
                  </div>
                </li>
              ))}
              <li className="time_line-end">
                <div className="time_line-badge warning">
                  <IoIosStar className="starIC"></IoIosStar>
                  <span className="badge_left"></span>
                  <span className="badge_right"></span>
                </div>
              </li>
            </ul>
            <h5 className="M_Certificate">Masters Certificate</h5>
          </div>
        </div>
      </div>
    </div>
  )
}

CourseDetails.propTypes = {
  CourseDetails: PropTypes.object,
}

export default CourseDetails
