import React from "react"
import PropTypes from "prop-types"

// import blogIMG1 from "../../images/blog_img01.jpg"

const Advantage = ({ advantage }) => {
  return (
    <div>
      <div className="Advantage">
        <div className="container">
          <div className="AdvantageWrap">
            <h4 className="sec-title">Advantages of this courses</h4>
            <div className="AdvantageBarScroll">
              <div className="AdvantageBar">
                <div className="AdvantageBarBg"></div>
                <div className="AdvantageFilled">
                  <span className="AdvantageFilledInner">
                    <span className="FilledBg"></span>
                    <span className="FilledCircle"></span>
                    <span className="FilledLableFilled">1,15,000 filled </span>
                  </span>
                </div>
                <span className="FilledLableZero">0</span>

                <span className="FilledLableAvailable">
                  5,00,0000 Jobs Available
                </span>
              </div>
            </div>
            <hr />
            <div className="AverageSalary">
              <div className="row text-center">
                {advantage.map(details => (
                  <div className="col" key={details.name}>
                    <h4>{`${
                      details.name === "Positions are available in India" ||
                      details.name === "Average Growth"
                        ? ""
                        : "$"
                    }${details.salary}`}</h4>
                    <p>{details.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Advantage.propTypes = {
  Advantage: PropTypes.object,
}

export default Advantage
