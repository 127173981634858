import React from "react"
import PropTypes from "prop-types"
import image2 from "../../images/ins_01.png"

const AboutInstructors = ({ instructors }) => {
  console.log("instructors>>", instructors)
  return (
    <div>
      <div className="AboutInstructors">
        <div className="container">
          <h4 className="title">Our Instructors</h4>
          {instructors && instructors.edges === undefined ? (
            <div>
              <div className="InstructorRow">
                <div className="instructorImg">
                  <img src={image2} className="img-fluid" />
                </div>
                <div className="Ins_details">
                  <h5 className="ins_name">Ryan Kolatalo</h5>
                  <p className="Experience">+5 Years Experience</p>
                  <p className="technologies">
                    HTML, CSS, Javascript, PHP, Wordpress
                  </p>
                  <p className="ins_breif">
                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus
                    sceler isque ante sollicitudin. Cras purus odio
                  </p>
                </div>
              </div>
            </div>
          ) : (
            instructors.map(instructorData => (
              <div className="InstructorRow" key={instructorData.id}>
                <div className="instructorImg">
                  <img
                    src={instructorData.image.fluid.srcWebp}
                    alt={instructorData.name}
                    className="img-fluid"
                  />
                </div>
                <div className="Ins_details">
                  <h5 className="ins_name">{instructorData.name}</h5>
                  <p className="Experience">{instructorData.experience}</p>
                  <p className="technologies">{instructorData.technologies}</p>
                  <p className="ins_breif">{instructorData.details.details}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

AboutInstructors.propTypes = {
  instructors: PropTypes.array,
}

export default AboutInstructors
