import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WelcomeBanner from "../components/WelcomeBanners"
import Advantage from "../components/Courses/Advantage"
import CourseDetails from "../components/Courses/CourseDetails"
import AboutInstructors from "../components/Courses/AboutInstructors"
import Trainers from '../components/Trainer/Trainers'

const CourseTemplate = ({ data }) => {
  const { courses } = data
  return (
    <Layout>
      <SEO title={courses.name} />
      <WelcomeBanner
        bannerTitle={courses.banner&&courses.banner.name}
        bannerSubTitle={courses.banner&&courses.banner.subtitle}
        bannerImage={courses.banner&&courses.banner.image.fluid}
        buttonTitle='Enroll Now'
      />
      <Advantage advantage={courses.salarydetails}></Advantage>
      <CourseDetails coursedetails={courses}></CourseDetails>
      <Trainers instructors={courses.instructors} isEdges={false}/>
    </Layout>
  )
}

export const blogsBySlugQuery = graphql`
query($slug: String!) {
  courses: contentfulBrainyFiedCourses(slug: { eq: $slug }) {
    id
    name
    opening
    slug
    status
    primaryColor
    image {
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    salarydetails {
      id
      name
      salary
    }
    details{
      details
    }
  weekstatus{
    week,
    details
    link
  }
  banner{
    name
    subtitle
    image{
      fluid{
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
  instructors{
    id
    name
    experience
    technologies
    details {
      details
    }
    image{
      fluid{
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
  }
}`

export default CourseTemplate
